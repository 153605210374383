import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel, MdAnalytics } from 'react-icons/md';
import { FaRecycle } from 'react-icons/fa';
import { GiWaterRecycling } from 'react-icons/gi';
import { BiAnalyse } from 'react-icons/bi';
import { SiKatana, SiAnalogue } from 'react-icons/si';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import { MdAccountBalanceWallet } from 'react-icons/md';
// import companyLogo from '../images/companyLogo.png';

const links = [
  {
    title: 'SALES',
    links: [
      {
        name: 'Party Summary (Credit)',
        linkName: 'InvWiseCredit_PartySumm',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Party Summary (Consigment)',
        linkName: 'InvWiseCons_PartySumm',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Party Summary (Merge)',
        linkName: 'InvWiseMerge_PartySumm',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Invoice Sales Summary',
        linkName: 'InvSalesSumm',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Item Sales Statement',
        linkName: 'ItemSalesStatement',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Item Wise Sales (Summary)',
        linkName: 'ItemWiseSaleSumm',
        // icon: <MdAccountBalanceWallet />,
      },



    ],
  },
  {
    title: 'Purchase',
    links: [
      {
        name: 'Purchase Check List',
        linkName: 'PurTransChkList',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Purchase Return Check List',
        linkName: 'PurReturnChkList',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Item Wise Purchase',
        linkName: 'ItemWisePurchase',
        // icon: <MdAccountBalanceWallet />,
      },
      {
        name: 'Supplier Ledger',
        linkName: 'SupplierLedgerSumm',
        // icon: <MdAccountBalanceWallet />,
      }
    ],
  },
]



const Sidebar = () => {
  let location = useLocation();

  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const handleCloseSiderBar = () => {
    if (activeMenu && screenSize <= 1500) {
      setActiveMenu(false);
    }
  }


  const activeLink = 'flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';
  const normalLink = 'flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10" style={{ backgroundColor: "#eeee" }}>
      {activeMenu && (<>
        <div className="flex justify-between items-center">
          <Link to="/" onClick={handleCloseSiderBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
            {/* <SiShopware />  */}
            {/* <img src={companyLogo}></img> */}
            <span>Madni Corporation</span>
          </Link>
          <TooltipComponent content="Menu" position="BottomCenter">
            <button type="button" onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)} className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
              <MdOutlineCancel />
            </button>
          </TooltipComponent>
        </div>
        <div className="mt-10">
          {links.map((item) => (<div key={item.title}>
            <p className="text-gray-400 m-3 mt-4 uppercase">
              {item.title}
              {/* DASHBOARD */}
            </p>
            {item.links.map((link) => (
              <NavLink to={`/${link.linkName}`}
                key={link.linkName}
                onClick={handleCloseSiderBar}
                style={({ isActive }) => ({
                  background: isActive ? "linear-gradient(60deg,#ab47bc, #8e24aa)" : '',
                  color: isActive ? ' #00000' : '',
                  fontWeight: isActive ? 'bold' : '',
                  boxShadow: "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
                })}
                className={({ isActive }) => (isActive ? activeLink : normalLink)}>

                {link.icon}
                <span className="capitalize">{link.name}</span>

              </NavLink>
            ))}
          </div>
          ))}
        </div>
      </>)}

    </div>
  )
}

export default Sidebar