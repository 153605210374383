import { React, useState, useEffect, Fragment } from 'react';
import { useLocation, Link, NavLink, useNavigate, Routes } from "react-router-dom";
import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat, HelpDialog } from '../components';
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as Breadcrumbslink } from '@mui/material';
import { MdOutlineFilterList } from "react-icons/md";

const useStyles = makeStyles({
    root: {
        width: "100%"
    },
    container: {
        maxHeight: "90vh",
    }
});

const { format } = require('date-fns');
let prevfrmDate;
let prevtoDate;

const ItemSalesStatement = (props) => {

    const classes = useStyles();

    const [ISSDATA, handleStats] = useState([]);
    const [frmDate, setfrmDate] = useState(new Date());//useState(subMonths(new Date(), 60));
    const [toDate, settoDate] = useState(new Date());

    let fromItem = '';
    let toItem = '';

    useEffect(() => {
        FetchData()
    }, [])

    function getDates() {
        const $frmDate = format(new Date(frmDate), 'yyyy-MM-dd');
        const $toDate = format(new Date(toDate), 'yyyy-MM-dd');
        setfrmDate($frmDate);
        settoDate($toDate);
      //  FetchData();
    }

    const FetchData = async () => {
        const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/ItemSalesStatement?FrmItemCode=' + fromItem + '&ToItemCode=' + toItem + '&FrmDate=' + format(new Date(frmDate), 'yyyy-MM-dd') + '&toDate=' + format(new Date(toDate), 'yyyy-MM-dd')); //data source;  
        const ISSDATA = await data.json()
        handleStats(ISSDATA)
        setFilter(ISSDATA);
    }

    if (prevfrmDate !== frmDate || prevtoDate !== toDate) {
       // FetchData();
        prevfrmDate = frmDate;
        prevtoDate = toDate;
    }
    // ------------------------- searching------------------------
    const [filter, setFilter] = useState([])
    const [showClearIcon, setShowClearIcon] = useState("none");
    const requestSearch = (searchedVal) => {
        setPage(0);
        setShowClearIcon(searchedVal === "" ? "none" : "flex");
        document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
        const filteredRows = ISSDATA.filter((row) => {
            return row.ItemCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
                || row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });

        if (searchedVal.length < 1) {
            setFilter(ISSDATA.ItemCode)
        }
        else {
            setFilter(filteredRows)
        }
    };
    //----------------grouping------------------------------
    const company_GROUP = [...ISSDATA.reduce((r, o) => {
        const key = o.ItemGrp;
        const item = r.get(key) || Object.assign({}, o);
        return r.set(key, item);
    }, new Map).values()];
    console.log(company_GROUP);

    //----pagination---------------------
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    ///--------------------HELP WORK START----------------------------
    const [helpFromItem, setHelpFromItem] = useState("");
    const [helpToItem, setHelpToItem] = useState("");

    const handleDataChangeFrmItm = (newData) => {
        setHelpFromItem(newData);
    };
    const handleDataChangeToItm = (newData) => {
        setHelpToItem(newData);
    };
    const handleChangeFrmItm = (e) => {
        setHelpFromItem(e.target.value);
    };
    const handleChangeToItm = (e) => {
        setHelpToItem(e.target.value);
    };

    // const handleKeyDown = (event) => {
    //   if (event.key === "F1") {
    //     event.preventDefault();

    //     document.getElementById("HelpDialog").click();
    //   }
    // }


    function getFromItem() {

        fromItem = document.getElementById("helpFromItemTextbox").value;
    }

    function getToItem() {

        toItem = document.getElementById("helpToItemTextbox").value;
    }

    function setFilterClick() {
        getFromItem();
        getToItem();
        getDates();
        FetchData();
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(true);

        //----TOTAL----------------------
        let total_ItemOpenBalance = 0;
        let total_PurdGrossQnty = 0;
        let total_PRetdGrossQnty = 0;
        let total_SaldGrossQnty = 0;
        let total_SRetdGrossQnty = 0;
        let total_TotalSales = 0;
        let total_BonusQnty = 0;
        let total_BlnceQnty = 0;
        let total_BlnceStock = 0;
        let total_GrossAmount = 0;

        return (
            <Fragment>
                {/* {console.log(filter.map((row) => { return row.ItemCode.toString() }))} */}
                {filter.map((row) => { return row.ItemCode.slice(0, 3).toString() }).includes(row.ItemCode) && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <StyledTableCell component="th" scope="row" style={{ fontWeight: "Bold" }}>
                        {row.ItemGrp}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ fontWeight: "Bold" }} >{row.ItemDesc}</StyledTableCell>
                </TableRow>}
                {filter.map((row) => { return row.ItemCode.slice(0, 3).toString() }).includes(row.ItemGrp) && <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                {/* <Typography variant="h6" gutterBottom component="div">
                                    {row.ItemDesc}
                                </Typography> */}
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            {/* <StyledTableHeadCell align="left">Item Code</StyledTableHeadCell> */}
                                            <StyledTableHeadCell align="left">Item Name & Unit</StyledTableHeadCell>
                                            {/* <StyledTableHeadCell align="left">Unit</StyledTableHeadCell> */}
                                            <StyledTableHeadCell align="right">Opening Stock</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Purchase Qnty</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Purchase Return</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Sale Qnty</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Sales Return</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Total Sales Qnty</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Bonus Qnty</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Balance Stock</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Trade Price</StyledTableHeadCell>
                                            <StyledTableHeadCell align="right">Sales Amount</StyledTableHeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter(Detrow => (Detrow.ItemCode.slice(0, 3) == row.ItemCode.slice(0, 3) && Detrow.ItemCode.slice(0, 3) != Detrow.ItemCode)).map((DetailRow) => (
                                            total_ItemOpenBalance += DetailRow.ItemOpenBalance * DetailRow.ItGdRate,
                                            total_PurdGrossQnty += DetailRow.PurdGrossValue,
                                            total_PRetdGrossQnty += DetailRow.PRetdGrossValue,
                                            total_SaldGrossQnty += DetailRow.SaldGrossAmnt,
                                            total_SRetdGrossQnty += DetailRow.SRetdGrossAmnt,
                                            total_TotalSales += DetailRow.SaldGrossAmnt - DetailRow.SRetdGrossAmnt,
                                            total_BonusQnty += DetailRow.BonusQnty,
                                            total_BlnceQnty = (((DetailRow.ItemOpenBalance + DetailRow.PurdGrossQnty) - DetailRow.PRetdGrossQnty - (DetailRow.SaldGrossQnty - DetailRow.SRetdGrossQnty)) - DetailRow.BonusQnty) * DetailRow.ItGdRate,
                                            total_BlnceStock += total_BlnceQnty,
                                            total_GrossAmount += DetailRow.GrossAmount,

                                            <TableRow key={DetailRow.ItemCode}>
                                                {/* <StyledTableCell component="th" scope="row">
                                                    {DetailRow.ItemCode}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="left" >
                                                    <div className='smallFont'>{DetailRow.ItemDesc}</div>
                                                    {DetailRow.ItemUnit}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="left">{DetailRow.ItemDesc}</StyledTableCell>
                                                <StyledTableCell align="left">{DetailRow.ItemUnit}</StyledTableCell> */}
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.ItemOpenBalance} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PurdGrossQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.PRetdGrossQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SaldGrossQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SRetdGrossQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.SaldGrossQnty - DetailRow.SRetdGrossQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.BonusQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={(((DetailRow.ItemOpenBalance + DetailRow.PurdGrossQnty) - DetailRow.PRetdGrossQnty - (DetailRow.SaldGrossQnty - DetailRow.SRetdGrossQnty))) - DetailRow.BonusQnty} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.ItGdRate} decimal={2} /></StyledTableCell>
                                                <StyledTableCell align="right"><NumberFormat value={DetailRow.GrossAmount} /></StyledTableCell>
                                            </TableRow>
                                        ))}
                                        <StyledTableRow>
                                            {/* <StyledTableFooterCell></StyledTableFooterCell> */}
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Sub Total</StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_ItemOpenBalance} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_PurdGrossQnty} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_PRetdGrossQnty} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SaldGrossQnty} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_SRetdGrossQnty} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_TotalSales} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_BonusQnty} /></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_BlnceStock} /></StyledTableFooterCell>
                                            {/* <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_ItGdRate}/></StyledTableFooterCell> */}
                                            <StyledTableFooterCell></StyledTableFooterCell>
                                            <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_GrossAmount} /></StyledTableFooterCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
            </Fragment>
        );
    }
    // Row.propTypes = {
    //     row: PropTypes.shape({
    //         calories: PropTypes.number.isRequired,
    //         carbs: PropTypes.number.isRequired,
    //         fat: PropTypes.number.isRequired,
    //         history: PropTypes.arrayOf(
    //             PropTypes.shape({
    //                 amount: PropTypes.number.isRequired,
    //                 customerId: PropTypes.string.isRequired,
    //                 date: PropTypes.string.isRequired,
    //             }),
    //         ).isRequired,
    //         name: PropTypes.string.isRequired,
    //         price: PropTypes.number.isRequired,
    //         protein: PropTypes.number.isRequired,
    //     }).isRequired,
    // };

    return (
        //requestSearch
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <HeaderSM category="" title="Item Sales Statement - Item Code Wise" />
            <div style={{ marginTop: "-1.5rem" }}>
                <TextField id="helpFromItemTextbox" value={helpFromItem} onChange={handleChangeFrmItm} label="From Item Code" variant="standard" //placeholder='Help Booker' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpItem'} setHelpData={handleDataChangeFrmItm} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <TextField id="helpToItemTextbox" value={helpToItem} onChange={handleChangeToItm} label="To Item Code" variant="standard" //placeholder='Help Sale Order' //onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { height: "32px" },
                        endAdornment: (
                            <IconButton>
                                <HelpDialog id="Model" helpName={'HelpItem'} setHelpData={handleDataChangeToItm} />
                            </IconButton>
                        )
                    }} />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="From Date"
                        value={frmDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                setfrmDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <DesktopDatePicker
                        label="To Date"
                        value={toDate}
                        onChange={(newValue) => {
                            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                                settoDate(newValue);
                            }
                        }}
                        inputFormat={"DD/MM/YYYY"}
                        renderInput={(params) => <TextField {...params} />}
                        className="sameLineTabledatetime"
                    />
                </LocalizationProvider>
                <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
                    <Button className='filterButton' onClick={setFilterClick}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
                </ButtonGroup>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => requestSearch("")}
                                style={{ display: showClearIcon }}
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
            {/* ----------------------*/}
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableHeadCell align="left">Item Code</StyledTableHeadCell>
                            <StyledTableHeadCell align="left">Item Name</StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(company_GROUP).map((row) => (
                            <Row key={row.Id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default ItemSalesStatement

